html{
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: #282c34;
}
.App {
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.Cat-Eyes{
  height: 100%;
  display: flex;
  flex-direction: column;
  /* justify-content: center; */
  align-items: center;
  
}

.App-header {
  position: relative;
  background-color: #282c34;
  margin: 10vh;
  /* min-height: 50vh; */
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: rgb(187, 187, 187);
}
h1 {
  /* font-size: 2em; */
  font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
  font-stretch: ultra-condensed;
  font-weight: 900;
  margin: auto;
  color: rgba(0, 21, 21, 0.461);
  text-align: center;
}
